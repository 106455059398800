"use strict";

const productNames = [];

const productsContainerEl = document.getElementById("products__container");
const productsEl = productsContainerEl.querySelectorAll(".product");

//populate the productNames array from the Product elements
productsEl.forEach((product) => {
  productNames.push(
    product.querySelector(".product__name").textContent.toLowerCase()
  );
});

const productSearchEL = document.querySelector(".products__search");
productSearchEL.addEventListener("input", (e) => {
  if (!productSearchEL.value) {
    productsEl.forEach((product) => (product.style.display = "flex"));
    return;
  }

  console.log(productSearchEL.value);

  productsEl.forEach((product) => {
    if (
      product
        .querySelector(".product__name")
        .textContent.toLowerCase()
        .includes(productSearchEL.value.toLowerCase())
    )
      product.style.display = "flex";
    else product.style.display = "none";
  });
});
